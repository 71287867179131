<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="null"
      :customSearchList="searchType.list"
      :searchObj="searchType.obj"
      @change-search-type="$_changeSearchType"
      @onSearchClick="searchData($event)"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes['type']"
          placeholder="구분"
          :items="searchType.typeList"
          display-expr="codeNm"
          value-expr="codeValue"
          :styling-mode="stylingMode"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('type', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="pickupGrid" />

    <DxPopup
      v-model="modal.isOpened"
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <ModalAddPickupEtc
        :isOpened="this.modal.isOpened"
        :cmList="this.cmList"
        :partitionList="this.partitionList"
        @closeModal="closeModal"
      />
    </DxPopup>
    <DxPopup
      v-model="infoModal.isOpened"
      :show-title="true"
      :title="infoModal.title"
      :width="infoModal.width"
      :height="infoModal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="infoModal.isOpened"
      @hiding="isOpenInfoModal(false)"
    >
      <ModalInfoPickup
        :isOpened="this.infoModal.isOpened"
        :rowInfo="this.rowInfo"
        :cmList="this.cmList"
        :type="this.type"
        :partitionList="this.partitionList"
        @closeModal="closeInfoModal"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import ModalAddPickupEtc from './modal-add-pickup-etc.vue';
  import ModalInfoPickup from './modal-info-pickup.vue';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxTextBox,
      DxCustomRule,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      Search,
      DxPopup,
      DxToolbarItem,
      ModalAddPickupEtc,
      ModalInfoPickup,
    },
    props: {
      cmList: {
        type: Array,
      },
      partitionList: {
        type: Array,
      },
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        password: '',
        rePassword: '',
        modal: {
          isOpened: false,
          title: '개별 당겨받기 설정',
          width: '50%',
          height: '650',
        },
        infoModal: {
          isOpened: false,
          title: '개별 당겨받기 정보',
          width: '50%',
          height: '750',
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          list: [
            {
              codeNm: '그룹명',
              codeValue: 'name',
            },
            {
              codeNm: '그룹번호',
              codeValue: 'pattern',
            },
            {
              codeNm: '설명',
              codeValue: 'description',
            },
          ],
          typeList: [
            {
              codeNm: '구분',
              codeValue: '0',
            },
            {
              codeNm: '개별',
              codeValue: 'ETC',
            },
            {
              codeNm: '부서',
              codeValue: 'DEPT',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            type: null,
          },
          paramsData: null,
        },
        type: '',
        // cmList: [],
        // partitionList: [],
        rowInfo: {},
        dataGrid: {
          excel: {
            title: '당겨받기 목록',
          },
          callApi: 'CALL_EUC_API',
          refName: 'pickupGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          //   height: '650', // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_PICKUP_UPDATE',
            delete: 'EUC_PICKUP_DELETE',
            loading: true,
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            excel: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.getCmNm(row?.cmNm),
            },
            {
              caption: '구분',
              dataField: 'type',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const spanTag = document.createElement('span');
                if (options.value === 'DEPT') {
                  spanTag.innerText = '부서';
                } else {
                  spanTag.innerText = '개별';
                }
                container.append(spanTag);
              },
            },
            {
              caption: '그룹명',
              dataField: 'name',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;

                aTag.addEventListener('click', async () => {
                  // await this.onSelectData(options.data, type);
                  await this.setType(options.data.type);
                  await this.setRowInfo(options.data);
                  await this.isOpenInfoModal(true);
                });
                container.append(aTag);
              },
            },
            {
              caption: '그룹번호',
              dataField: 'pattern',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파티션',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
            },
            {
              caption: '등록 내선',
              dataField: 'count',
              // dataField: 'directorylist',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              dataField: 'editDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: row => this.formatDt(row?.regDt),
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (this.searchType.paramsData != null) {
            if (Object.keys(this.searchType.paramsData).length > 0) {
              Object.keys(this.searchType.paramsData).forEach(key => {
                if (codeValues.includes(key)) {
                  delete this.searchType.paramsData[key];
                }
              });
            }
          }
        }
        this.selectDataList();
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenModal(data) {
        this.modal.isOpened = data;
      },
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenInfoModal(data) {
        this.infoModal.isOpened = data;
      },
      async selectDataList(sort = '+callpickupGroupOrd , +id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            // /**검색조건이 0(전체) 일경우 파라미터 삭제 */
            if (params?.['type'] === '0') {
              delete params['type'];
            }

            const payload = {
              actionname: 'EUC_PICKUP_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '삭제',
              elementAttr: { class: 'btn_XS white light_filled trash' },
              width: 60,
              height: 30,
              onClick: () => {
                this.onDeletePickup();
              },
            },
            location: 'before',
          },
        ];

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            // text: '개별 추가',
            text: '추가',
            elementAttr: { class: 'btn_XS default filled ' },
            // width: 80,
            height: 30,
            onClick: () => {
              this.isOpenModal(true);
            },
          },
          location: 'before',
        });

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: '당겨받기 동기화',
            elementAttr: { class: 'btn_XS default filled ' },
            // width: 120,
            height: 30,
            onClick: () => {
              this.onSyncPickupGroup();
            },
          },
          location: 'before',
        });

        return buttons;
      },
      /**@description : 당겨받기관리 모달 close 콜백함수 */
      async closeModal(flag) {
        if (flag) {
          this.isOpenModal(false);
        }
      },
      /**@description : 당겨받기 리스트 모달 close 콜백함수 */
      closeInfoModal(data) {
        this.isOpenInfoModal(false);
      },
      setType(data) {
        this.type = data;
        if (data === 'DEPT') {
          this.infoModal.title = '부서별 당겨받기 정보';
        } else {
          this.infoModal.title = '개별 당겨받기 정보';
        }
      },
      setRowInfo(data) {
        this.rowInfo = data;
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 교환기 이름 변환 메서드 */
      getCmNm(data) {
        if (data) {
          let cmNm = this.cmList.filter(d => d.cmNm == data);
          return cmNm[0].description;
        }
      },
      /** @description : 당겨받기 동기화 메서드 */
      async onSyncPickupGroup() {
        let confirm = this.$_msgContents('UC.MESSAGE.START_SYNC');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }

        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          const payload = {
            actionname: 'EUC_PICKUP_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.pickupGrid.getGridInstance.refresh();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
            }
          }
        }
      },
      /**@description 내선삭제 메서드 */
      async onDeletePickup() {
        const selectedRowsData = this.$refs.pickupGrid.selectedRowsData;
        if (!selectedRowsData?.length) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        } else if (selectedRowsData?.length > 1) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ONE_SELECT'));
          return;
        } else if (selectedRowsData?.length == 1) {
          let params = {
            cmNm: selectedRowsData[0].cmNm,
            loginId: this.$store.getters.getLoginId,
            name: selectedRowsData[0].name,
            partition: selectedRowsData[0].routepartitionname,
            parttern: selectedRowsData[0].pattern,
          };
          const payload = {
            actionname: 'EUC_PICKUPGROUP_DELETE',
            data: params,
            loading: true,
          };

          let confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE');

          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.pickupGrid.getGridInstance.clearSelection();
            await this.$refs.pickupGrid.getGridInstance.refresh();
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        this.searchType.paramsData = null;
        let params = this.searchType.typeList[0].codeValue;
        this.setCustomTypes('type', params);
        await this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
